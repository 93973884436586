import React, { useEffect } from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/FiveColumnWithBackground.js";
import macHeroScreenshotImageSrc from "images/realiza.png";
import persona1 from "images/persona1.jpeg";
import persona2 from "images/persona2.jpeg";
import persona3 from "images/persona3.jpeg";
import persona4 from "images/persona4.jpeg";
import persona5 from "images/persona5.jpeg";




export default () => {
    /*
   * Using gtag like this because we only want to use Google Analytics when Main Landing Page is rendered
   * Remove this part and the the gtag script inside public/index.html if you dont need google analytics
   */
    useEffect(() => {
      window.gtag("js", new Date());
      window.gtag("config", "G-J58L0ER59H");
    }, [])
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} />
      <FeatureWithSteps
        subheading={<Subheading style={{ color: '#4200FF'}}>PASOS</Subheading>}
        heading={
          <>
            Reparamos tu Notebook o PC en <HighlightedText style={{ color: '#4200FF'}} >3 Pasos</HighlightedText>
          </>
        }
        textOnLeft={false}
        imageSrc={macHeroScreenshotImageSrc}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />
      <Features
        subheading={<Subheading style={{ color: '#4200FF'}}>SERVICIOS</Subheading>}
        heading={
          <>
            Todos Nuestros Servicios Tienen <HighlightedText style={{ color: '#4200FF'}}>Garantía.</HighlightedText>
          </>
        }
      />
     <GetStarted/>
      <Testimonial
        subheading={<Subheading style={{ color: '#4200FF'}}>OPINIONES</Subheading>}
        heading={
          <>
            Nuestros Clientes Opinan <HighlightedText style={{ color: '#4200FF'}}>de Nuestro Trabajo</HighlightedText>
          </>
        }
        testimonials={[
          {
            stars: 5,
            profileImageSrc:
              persona2,
            heading: "Muy recomendado.",
            quote:
              "Excelente atención y servicio. Trabajo eficiente y de alta calidad. Muy recomendado.",
            customerName: "Valentina Méndez Pereira",
            customerTitle: "Coordinadora Programa Quiero Ser Profe"
          },
          {
            stars: 5,
            profileImageSrc:
            persona1,
            heading: "Excelente trabajo",
            quote:
              "Excelente trabajo y cálidad persona muy amable totalmente recomendado",
            customerName: "Maeckol Andres Opazo Soth",
            customerTitle: ""
          },
          {
            stars: 5,
            profileImageSrc:
             persona3,
            heading: "Excelente servicio!",
            quote:
              "Excelente servicio, lo recomiendo al 100%",
            customerName: "Jonathan Araos",
            customerTitle: "Periodista"
          },
          {
            stars: 5,
            profileImageSrc:
             persona4,
            heading: "Excelente servicio!",
            quote:
              "Muy buen servicio, profesionalismo y rapidez. 100% recomendado.",
            customerName: "Maurën Araos Pereira",
            customerTitle: "Periodista"
          },
          {
            stars: 5,
            profileImageSrc:
             persona5,
            heading: "Rápido y confiable",
            quote:
              "Rápido y confiable 100% recomendable",
            customerName: "Gregory Ayala Villa",
            customerTitle: ""
          }
        ]}
      />
      
      <Footer />
    </AnimationRevealPage>
  );
}
